@import '~antd/dist/antd.css';

// Company name
.Matador{
background: #4d2610b3;
transition: all .9s ease-in;
}

.Molex{
	background: #e30536 !important;
	transition: all .9s ease-in;
	
}
.Trading{
	background: #123b62 !important;
	transition: all .9s ease-in;
}
.Minerals{
background: rgba(0,168,225,1) !important;
transition: all .9s ease-in;
}
.Equity {
	background: #00A8E0 !important;
	transition: all .9s ease-in;
}
.Disruptive {
	background: rgb(11, 12, 13) !important;
	transition: all .9s ease-in;
}
.Engineered {
background: rgba(0,168,225,1) !important;
transition: all .9s ease-in;
}
.Energy {
background: #0082ca !important;
transition: all .9s ease-in;
}
.Invista{
background: #e3a916 !important;
transition: all .9s ease-in;
}
.Infor{
	background: #0072ed !important;
	transition: all .9s ease-in;
}
.Guardian {
background: #181818 !important;
transition: all .9s ease-in;
}
.Georgia {
background: #004a88 !important;
transition: all .9s ease-in;
}
.Flint {
background: #c5c09f !important;
transition: all .9s ease-in;
}
.Technology {
background: #b9dded !important;
transition: all .9s ease-in;
}

.common_btn {
	border-color: transparent !important;
	&:hover {
		background: black !important;
		border-color: black !important ;
	}
}

.logoTitlte {
	margin: 0 0 0 0;
    font-size: 15px;
    font-weight: 900;
    line-height: 18px;
    color: white;
	@media (max-width: 1023px) {
		font-size: 8px;
	}
}

.ant-badge-dot {
	background: #3a7ada;
}
// .Search_Header{
//   height: 50px;
//   line-height: 50px;
//   box-shadow: 0 8px 20px 0 rgba(33,43,54,.12);
//   border-bottom: 1px solid #dfe3e8;
//   @media (min-width: 320px) and (max-width: 480px) {
//     height: 64px;
//     line-height: 64px;
//   }
// }
.A_area {
	background: white;
	padding: 5px;
	h1 {
		color: #1d74d8;
		margin: 0;
		font-size: small;
		margin: 5px 5px 5px 0px;
		text-transform: capitalize;
	}
}
.single_Card {
	border: 0.5px solid #e7e7e7;
	cursor: pointer;
	&:hover {
		background: #f0f0f0;
	}

	.abbr {
		margin: 0 0 0 0;
		background-color: #dddddd;
		text-transform: uppercase;
		text-align: center;
		font-size: larger;
		font-weight: bold;
		padding: 10px 0;
		letter-spacing: 1px;
		overflow: hidden !important;
		text-overflow: ellipsis;
	}
	.def {
		margin: 0 0 0 0;
		font-size: larger;
		font-weight: normal;
		padding: 10px 10px;
		text-transform: capitalize;
	}
}






.Add_Acronym {
	h1 {
		text-align: center;
		font-size: large;
		font-weight: bold;
		background: whitesmoke;
		box-shadow: 0 8px 20px 0 rgba(33, 43, 54, 0.12);
	}
	.Add_single_input {
		p {
			span {
				color: red;
			}
			margin-bottom: 5px;
			margin-top: 10px;
			font-weight: 700;
			text-transform: uppercase;
			font-size: 12px;
			letter-spacing: 1px;
		}
	}
}

.View_single_input {
	p {
		margin-bottom: 5px;
		margin-top: 10px;
		font-weight: 700;
		text-transform: uppercase;
		font-size: 12px;
		letter-spacing: 1px;
		text-align: end;
		margin-right: 10px;
		@media (min-width: 320px) and (max-width: 480px) {
			text-align: start;
		}
	}
}
.detail {
	font-size: small;
	margin-bottom: 5px;
	margin-top: 10px;
	@media (min-width: 320px) and (max-width: 480px) {
		margin: 0;
	}
}

.loader_full_screen {
	text-align: center;
	background: rgba(0, 0, 0, 0.05);
	position: absolute;
	height: 100vh;
	width: 100%;
	z-index: 2;
	.ant-spin {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		width: 100%;
	}
}

.overlaydrop {
	max-height: 80vh !important;
	overflow-y: scroll !important;
	overflow-x: hidden;
	box-shadow: 0 8px 20px 0 rgba(33, 43, 54, 0.12);
	border: 1px solid #dfe3e8;
	z-index: 2;
}
.overlaydrop {
	-ms-overflow-style: none; /* Internet Explorer 10+ */
	scrollbar-width: none; /* Firefox */
}
.overlaydrop::-webkit-scrollbar {
	display: none; /* Safari and Chrome */
}

.ant-descriptions-view {
	.ant-descriptions-row {
		.ant-descriptions-item-label {
			font-size: 14px;
			font-weight: 600;
			letter-spacing: 1px;
			text-transform: uppercase;
			background-color: #e305361c;
		}
	}
}
.company_filter {
	// display: flex;
	.ant-select-focused .ant-select-selector,
.ant-select-selector:focus,
.ant-select-selector:active,
.ant-select-open .ant-select-selector {
  border-color: #d9d9d9 !important;
  box-shadow: none !important;
}
	.ant-select-arrow {
		color: white !important;
		border-color: transparent !important;
		
	}
	.ant-select-single:not(.ant-select-customize-input) .ant-select-selector{
		display: flex;
		align-items: center;
		height: 45px;
		border-color: transparent !important;
		@media (min-width: 1440px) {
			height: 55px;
		  }
	}

	.ant-select-selector {
		height: 45px;
		background: transparent !important;
		border-color: transparent !important;
		.ant-select-selection-item {
			color: white;
			font-weight: 600;
			opacity: 1;
		}
	}
}

.noData {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	width: 100%;
	text-align: center;
}


#Home_header{
	height: 50px;
	 line-height: 50px;	 
	 @media (min-width: 1440px) {
		height: 69px;
	 	line-height: 69px;
	  }
}
.Home_content{
	margin-top: 50px;
	@media (min-width: 1440px) {
		margin-top: 69px;
	  }
}
.company_Filter_height{
	height: 45px;
	@media (min-width: 1440px) {
		height: 55px;
	  }
}
 